import * as React from "react"
import styled from "styled-components"
import Badge from "./Badge"

const SayHello = () => {
  return (
    <BlockContainer className="py-5">
      <div className="container text-start card py-2 py-md-4">
        <CardContainer className="p-2 p-md-4">
          <Badge>Say Hello!</Badge>
          <div className="row">
            <div className="col">
              <h2>We'd Love To Hear From You</h2>
              <a href="mailto:info@slaightmusic.com" className="bold lead">info@slaightmusic.com</a>
            </div>
            <div className="col d-flex align-items-center">
              <p className="px-md-5">Please send an email to the address at left and we'll do our best to get back to you within three business days.</p>
            </div>
          </div>
        </CardContainer>
      </div>
    </BlockContainer>
  )
}

const BlockContainer = styled.div``

const CardContainer = styled.div`
  color: #000000;
`

export default SayHello
