import * as React from "react"
import Layout from "../components/layout/layout"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { getMachineName, mapSocialLinks } from "../lib/helper"
import SocialLinks from "../components/common/SocialLinks"
import SayHello from "../components/common/SayHello"
import { navigate } from "@reach/router"
import Badge from "../components/common/Badge"
import { SEO as SearchEngineOptimization } from "../components/common/seo"
import { DEFAULT_SITE_DESCRIPTION } from "../lib/helper"

const TeamPage = ({ data }) => {
  function scrollToTop() {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }
  React.useEffect(() => {
    const handleSlide = event => {
      scrollToTop()
    }
    if (typeof window !== "undefined") {
      let carousel = document.getElementById("team-carousel")
      if (carousel) {
        carousel.addEventListener("slide.bs.carousel", event =>
          handleSlide(event)
        )
      }
    }
    return () => {
      if (typeof window !== "undefined") {
        let carousel = document.getElementById("team-carousel")
        if (carousel) {
          carousel.removeEventListener("slide.bs.carousel", handleSlide)
        }
      }
    }
  }, [])
  const entities = data?.drupal?.nodeQuery?.entities || []
  const indexedEntities = entities.map((entity, index) => {
    const slug = getMachineName(entity?.entityLabel)
    return slug
  })
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.hash) {
        const slug = window.location.hash.replace("#", "").trim()
        console.debug("team member slug => ", slug)
        let index = indexedEntities.findIndex(element => element === slug)
        console.debug("team member slide index => ", index)
        if (index === -1) index = 0 // Go to the first slide.
        setActiveSlideIndex(index)
        scrollToTop()
      }
    }
    return () => {}
  }, [indexedEntities])
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)

  return (
    <Layout>
      <Badge color="#ffffff">About</Badge>
      <div
        id="team-carousel"
        className="carousel slide py-5"
        data-bs-ride="carousel"
        data-bs-interval="9999999"
      >
        <div className="carousel-inner">
          {entities.map((entity, index) => {
            const badge = entity?.badge || "Unknown"
            const thumbnail = getImage(entity?.thumbnail || {})
            const slug = getMachineName(entity?.entityLabel)
            const url = `/team#${slug}`
            return (
              <div
                key={index}
                className={`carousel-item ${
                  index === activeSlideIndex ? "active" : ""
                }`}
              >
                <div className="row">
                  <div className="col col-12 col-sm-12 col-md-6 order-1 order-md-0">
                    <h1>
                      <Link to={url} className="white">
                        {entity?.entityLabel} — {badge}
                      </Link>
                    </h1>
                    <div
                      dangerouslySetInnerHTML={{ __html: entity?.body?.value }}
                    />
                  </div>
                  <div className="col col-12 col-sm-12 col-md-6 order-0 order-md-1 mb-4 mb-md-0">
                    <GatsbyImage
                      image={thumbnail}
                      alt={entity?.entityLabel}
                      className="rounded-4"
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="text-center py-5">
        <Badge color="#ffffff">Team</Badge>
        <h2 className="mb-4">
          The <span className="purple-underline">Slaight Mu</span>sic Team
        </h2>
        <div className="row justify-content-md-center justify-content-start team-grid pt-2">
          {entities.map((entity, index) => {
            const badge = entity?.badge || "Unknown"
            const thumbnail = getImage(entity?.smallThumbnail || {})
            const slug = getMachineName(entity?.entityLabel)
            const url = `/team#${slug}`
            const socialLinks = mapSocialLinks(entity?.socialLinks || [])
            return (
              <div
                key={index}
                className={`team-item col gx-5 col-6 col-md-4 align-self-start${
                  (index + 1) % 3 === 2 ? " bigger" : " mt-4"
                }`}
                data-bs-target="#team-carousel"
                data-bs-slide-to={index}
                onKeyPress={() => {
                  if (typeof window !== "undefined") {
                    navigate(url) // Update hash value.
                  }
                }}
                onClick={() => {
                  if (typeof window !== "undefined") {
                    navigate(url) // Update hash value.
                  }
                }}
                role="main"
              >
                <Link
                  to={url}
                  onClick={() => {
                    if (typeof window !== "undefined") {
                      navigate(url) // Update hash value.
                    }
                  }}
                >
                  <GatsbyImage
                    image={thumbnail}
                    alt={entity?.entityLabel}
                    className="rounded-4 mb-4"
                  />
                </Link>
                <div
                  className="p-md-4 pt-4 pt-md-0"
                  style={{
                    textAlign: "start",
                  }}
                >
                  <h3>
                    <Link
                      to={url}
                      className="white"
                      onClick={() => {
                        if (typeof window !== "undefined") {
                          navigate(url) // Update hash value.
                        }
                      }}
                    >
                      {entity?.entityLabel}
                    </Link>
                  </h3>
                  <span>{badge}</span>
                  <div className="py-2">
                    <SocialLinks socialLinks={socialLinks}></SocialLinks>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <SayHello />
    </Layout>
  )
}

export default TeamPage

export const pageQuery = graphql`
  query {
    drupal {
      nodeQuery(
        filter: {
          conditions: [
            { field: "status", value: "1", operator: EQUAL }
            { field: "type", value: "team_member", operator: EQUAL }
          ]
        }
        sort: { field: "draggable_views_weight" }
        offset: 0
        limit: 12
      ) {
        count
        entities {
          entityId
          entityLabel
          entityUrl {
            path
          }
          ... on Default_NodeTeamMember {
            image: fieldImage {
              url
              id: targetId
              alt
              title
            }
            body {
              value: processed
              summary: summaryProcessed
            }
            thumbnail: gatsbyTeamMemberImage {
              childImageSharp {
                gatsbyImageData(width: 400, height: 450)
              }
            }
            smallThumbnail: gatsbyTeamMemberImageThumbnail {
              childImageSharp {
                gatsbyImageData(width: 400, height: 450)
              }
            }
            badge: fieldTitle
            socialLinks: fieldSocialLink {
              url {
                path
              }
            }
          }
        }
      }
    }
  }
`

export const Head = () => {
  return (
    <SearchEngineOptimization
      title="Team"
      description={DEFAULT_SITE_DESCRIPTION}
      pathname="/team"
    />
  )
}
