import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const SocialLinks = ({ socialLinks = [] }) => {
  return (
    <StyledList className="nav justify-content-start">
      {['FB', 'TW', 'IN', 'BE'].map((item, index) => {
        if (socialLinks[item]) {
          return (
            <li className="nav-item pe-2 bold" key={index}>
              <Link to={socialLinks[item]}>{item}</Link>
            </li>
          )
        }
        return (
          <></>
        )
      })}
    </StyledList>
  )
}

const StyledList = styled.ul`
  margin-left: 0px;
`

export default SocialLinks
